/* --- SP ---------- */
@media screen and (max-width: 768px) {
	.detail .sec-product .inner {
	}
	.detail .sec-product .inner .ly-img {
		box-sizing: border-box;
		margin-bottom: 30px;
	}
	.detail .sec-product .inner .ly-img ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.detail .sec-product .inner .ly-img .bl-img-main {
		margin-bottom: 15px;
	}
	.detail .sec-product .inner .ly-img .bl-img-main li {
		position: relative;
		width: 100%;
	}
	.detail .sec-product .inner .ly-img .bl-img-main .extand {
		padding-bottom: 100%;
	}
	.detail .sec-product .inner .ly-img .bl-img-main li img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li {
		display: block;
		width: 32%;
		margin: 0 2% 2% 0;
		position: relative;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub .extand {
		padding-top: 32%;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li:nth-of-type(3n) {
		margin: 0 0 2% 0;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li.active img {
		border: 2px solid var(--main);
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li:hover img {
		opacity: 0.85;
	}
	.detail .sec-product .inner .ly-desc {
	}
	.detail .sec-product .inner .el-pd-ttl {
		font-size: 15px;
		font-weight: bold;
	}
	.detail .sec-product .inner .ly-desc .bl-price {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #dcdcdc;
		padding-bottom: 25px;
		margin-bottom: 25px;
	}
	.detail .sec-product .inner .ly-desc .bl-price p {
		margin: 0;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-price {
		font-size: 15px;
		font-weight: bold;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-price .small {
		font-size: 13px;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-shipping {
		background-color: #f3f3f3;
		padding: 7px 20px;
		font-size: 12px;
	}
	.detail .sec-product .inner .ly-desc .el-desc {
	}
	.detail .sec-product .inner .ly-desc .el-caution {
		color: #e6002e;
	}
	.detail .sec-product .inner .ly-desc .bl-btn {
		text-align: center;
	}
	.detail .sec-product .inner .ly-desc .bl-btn .el-btn {
		display: block;
		font-weight: bold;
		width: 100%;
		text-align: center;
		background-color: var(--main);
		padding: 20px 0;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;
		margin: 5px auto;
	}
	.detail .sec-desc .el-title {
		font-size: 16px;
		font-weight: bold;
		border-bottom: 1px solid #dcdcdc;
		box-sizing: border-box;
		padding-bottom: 1em;
		margin-top: 0;
	}
	.detail .sec-desc .el-title:not(:nth-of-type(1)) {
		margin-top: 50px;
	}
	.detail .sec-desc .el-desc {
	}
	.detail .sec-desc .bl-recommend {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.detail .sec-desc .bl-recommend .box {
		display: block;
		width: 47.5%;
		margin-bottom: 5%;
	}
	.detail .sec-desc .bl-recommend .box .bl-img {
		text-align: center;
		overflow: hidden;
	}
	.detail .sec-desc .bl-recommend .box .bl-img .el-img {
		transition: 0.5s;
	}
	.detail .sec-desc .bl-recommend .box:hover .bl-img .el-img {
		will-change: transform;
		-webkit-transform: scale(1.025) rotate(0.001deg);
		-ms-transform: scale(1.025) rotate(0.001deg);
		transform: scale(1.025) rotate(0.001deg);
	}
	.detail .sec-desc .bl-recommend .box .el-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		margin: 0;
		font-size: 13px;
	}
	.detail .sec-desc .bl-recommend .box .el-price {
		margin: 0;
		font-size: 13px;
		font-weight: bold;
	}
}
@media screen and (max-width: 420px) {
}
/* --- PC ---------- */
@media screen and (min-width: 769px) {
	.detail .sec-product .inner {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.detail .sec-product .inner .ly-img {
		width: 50%;
		padding-right: 10%;
		box-sizing: border-box;
	}
	.detail .sec-product .inner .ly-img ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}
	.detail .sec-product .inner .ly-img .bl-img-main {
		margin-bottom: 15px;
	}
	.detail .sec-product .inner .ly-img .bl-img-main li {
		position: relative;
		width: 100%;
	}
	.detail .sec-product .inner .ly-img .bl-img-main .extand {
		padding-bottom: 100%;
	}
	.detail .sec-product .inner .ly-img .bl-img-main li img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li {
		display: block;
		width: 32%;
		margin: 0 2% 2% 0;
		position: relative;
	}

	.detail .sec-product .inner .ly-img .bl-img-sub .extand {
		padding-top: 32%;
	}

	.detail .sec-product .inner .ly-img .bl-img-sub li:nth-of-type(3n) {
		margin: 0 0 2% 0;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		cursor: pointer;
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li.active img {
		border: 2px solid var(--main);
	}
	.detail .sec-product .inner .ly-img .bl-img-sub li:hover img {
		opacity: 0.85;
	}
	.detail .sec-product .inner .ly-desc {
		width: 50%;
	}
	.detail .sec-product .inner .el-pd-ttl {
		font-size: 23px;
		font-weight: bold;
	}
	.detail .sec-product .inner .ly-desc .bl-price {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		border-bottom: 1px solid #dcdcdc;
		padding-bottom: 25px;
		margin-bottom: 25px;
	}
	.detail .sec-product .inner .ly-desc .bl-price p {
		margin: 0;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-price {
		font-size: 23px;
		font-weight: bold;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-price .small {
		font-size: 18px;
	}
	.detail .sec-product .inner .ly-desc .bl-price .el-shipping {
		background-color: #f3f3f3;
		padding: 7px 20px;
		font-size: 12px;
	}
	.detail .sec-product .inner .ly-desc .el-desc {
		margin-bottom: 60px;
	}
	.detail .sec-product .inner .ly-desc .el-caution {
		color: #e6002e;
		margin-bottom: 60px;
	}
	.detail .sec-product .inner .ly-desc .bl-btn {
		text-align: center;
	}
	.detail .sec-product .inner .ly-desc .bl-btn .el-btn {
		display: block;
		font-weight: bold;
		width: 100%;
		text-align: center;
		background-color: var(--main);
		padding: 20px 0;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;
		margin: 5px auto;
	}
	.detail .sec-desc .el-title {
		font-size: 20px;
		font-weight: bold;
		border-bottom: 1px solid #dcdcdc;
		box-sizing: border-box;
		padding-bottom: 1em;
		margin-top: 0;
	}
	.detail .sec-desc .el-title:not(:nth-of-type(1)) {
		margin-top: 50px;
	}
	.detail .sec-desc .el-desc {
	}
	.detail .sec-desc .bl-recommend {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.detail .sec-desc .bl-recommend .box {
		display: block;
		width: 32%;
		margin: 0 2% 2% 0;
	}
	.detail .sec-desc .bl-recommend .box:nth-of-type(3n) {
		margin: 0 0 2% 0;
	}
	.detail .sec-desc .bl-recommend .box .bl-img {
		text-align: center;
		overflow: hidden;
	}
	.detail .sec-desc .bl-recommend .box .bl-img .el-img {
		transition: 0.5s;
	}
	.detail .sec-desc .bl-recommend .box:hover .bl-img .el-img {
		will-change: transform;
		-webkit-transform: scale(1.025) rotate(0.001deg);
		-ms-transform: scale(1.025) rotate(0.001deg);
		transform: scale(1.025) rotate(0.001deg);
	}
	.detail .sec-desc .bl-recommend .box .el-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 13px;
	}
	.detail .sec-desc .bl-recommend .box .el-price {
		font-size: 13px;
		font-weight: bold;
	}
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
}
