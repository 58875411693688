/* --- SP ---------- */
@media screen and (max-width: 768px) {
	.frontpage .sec-main {
		padding: 0;
	}
	.frontpage .sec-main .bl-img {
		text-align: center;
	}
	.frontpage .sec-main .bl-img .el-img {
		border-bottom: 2px solid var(--main);
	}
	.frontpage .sec-main .bl-img .el-img {
		max-width: none;
		width: 100%;
		height: 220px;
		object-fit: cover;
	}
	.frontpage .sec-main .bl-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
		max-width: 1100px;
		width: 100%;
	}
	.frontpage .sec-main .bl-title .el-title {
		text-align: center;
		color: #fff;
		font-weight: bold;
		font-size: 30px;
		text-shadow: 4px 3px 6px #4a4a4a;
		line-height: 1;
		margin: 0;
	}
	.frontpage .sec-main .bl-title .el-title span {
		font-size: 20px;
	}
	.frontpage .sec-archive .inner {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		margin: 16px auto;
	}
	.frontpage .sec-archive .inner .box {
		display: block;
		width: 47.5%;
		margin-bottom: 5%;
	}
	.frontpage .sec-archive .inner .box .bl-img {
		text-align: center;
		overflow: hidden;
	}
	.frontpage .sec-archive .inner .box .bl-img .el-img {
		transition: 0.5s;
	}
	.frontpage .sec-archive .inner .box:hover .bl-img .el-img {
		will-change: transform;
		-webkit-transform: scale(1.025) rotate(0.001deg);
		-ms-transform: scale(1.025) rotate(0.001deg);
		transform: scale(1.025) rotate(0.001deg);
	}
	.frontpage .sec-archive .inner .box .el-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		font-size: 13px;
	}
	.frontpage .sec-archive .inner .box .el-price {
		font-size: 13px;
		font-weight: bold;
	}
}
@media screen and (max-width: 420px) {
}
/* --- PC ---------- */
@media screen and (min-width: 769px) {
	.frontpage .sec-main {
		padding: 0;
	}
	.frontpage .sec-main .bl-img {
		text-align: center;
	}
	.frontpage .sec-main .bl-img .el-img {
		border-bottom: 5px solid var(--main);
	}
	.frontpage .sec-main .bl-title {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
		max-width: 1100px;
		width: 100%;
	}
	.frontpage .sec-main .bl-title .el-title {
		text-align: center;
		color: #fff;
		font-weight: bold;
		font-size: 100px;
		text-shadow: 4px 3px 6px #4a4a4a;
		line-height: 1;
		margin: 0;
	}
	.frontpage .sec-main .bl-title .el-title span {
		font-size: 55px;
	}
	.frontpage .sec-archive .inner {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.frontpage .sec-archive .inner .box {
		display: block;
		width: 32%;
		margin: 0 2% 2% 0;
	}
	.frontpage .sec-archive .inner .box:nth-of-type(3n) {
		margin: 0 0 2% 0;
	}
	.frontpage .sec-archive .inner .box .bl-img {
		text-align: center;
		overflow: hidden;
	}
	.frontpage .sec-archive .inner .box .bl-img .el-img {
		transition: 0.5s;
	}
	.frontpage .sec-archive .inner .box:hover .bl-img .el-img {
		will-change: transform;
		-webkit-transform: scale(1.025) rotate(0.001deg);
		-ms-transform: scale(1.025) rotate(0.001deg);
		transform: scale(1.025) rotate(0.001deg);
	}
	.frontpage .sec-archive .inner .box .el-desc {
		overflow: hidden;
		text-overflow: ellipsis;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		font-size: 13px;
	}
	.frontpage .sec-archive .inner .box .el-price {
		font-size: 13px;
		font-weight: bold;
	}
}
@media screen and (min-width: 769px) and (max-width: 1366px) {
	.frontpage .sec-main .bl-title .el-title {
		font-size: 70px;
	}
	.frontpage .sec-main .bl-title .el-title span {
		font-size: 40px;
	}
}
