.w75per {
	width: 75%;
}
.w50per {
	width: 50%;
}
.w25per {
	width: 25%;
}
.newline {
	white-space: pre-wrap;
}
.cursor-pointer {
	cursor: pointer;
}
[hidden] {
	display: none !important;
}
