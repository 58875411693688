/* --- SP ---------- */
@media screen and (max-width: 1024px) {
	main.customize {
		padding: 0;
	}
	main.customize section {
		padding: 0;
	}
	.customize .sec-top {
		border-top: 8px solid var(--main);
		background-color: #f5f5f5;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 80px;
		box-sizing: border-box;
	}
	.customize .sec-top .el-text {
		font-weight: bold;
		font-size: 14px;
		margin-left: 100px;
		width: calc(100% - 100px - 130px);
	}
	.customize .sec-top .bl-btn {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.customize .sec-top .bl-btn .el-btn {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0 30px;
		text-align: center;
		height: 72px;
		background-color: #333;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;
	}
	.customize .sec-top .bl-btn .el-btn:hover {
		background-color: #000;
	}
	.customize .sec-top .bl-btn.goback {
		right: auto;
		left: 0;
	}
	.customize .sec-top .bl-btn.goback .el-btn {
		padding: 0 30px;
	}
	.customize .sec-mid {
		background-color: #e4e4e4;
		min-height: calc(100vh + 150px);
	}
	.customize .sec-mid .inner {
		max-width: none;
		box-sizing: border-box;
	}
	.customize .sec-mid .ly-view {
		position: relative;
		padding: 30px 0 0;
	}
	.customize .sec-mid .ly-view .el-title {
		font-size: 18px;
		font-weight: bold;
		color: #7e7e7e;
	}
	.customize .sec-mid .ly-view .bl-calendar {
	}
	.customize .sec-mid .ly-view .bl-calendar .el-calendar {
		width: 100%;
	}
	.customize .sec-mid .bl-next {
		display: inline-block;
		text-align: center;
		float: right;
	}
	.customize .sec-mid .bl-next .el-text {
		line-height: 1;
		position: relative;
		color: #7e7e7e;
		font-size: 13px;
		font-weight: bold;
		padding-left: 80px;
	}
	.customize .sec-mid .bl-next .el-text::after {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 17px 0 17px 22px;
		border-color: transparent transparent transparent #c0191f;
		margin: 0 auto;
	}
	.customize .sec-mid .bl-previous {
		display: inline-block;
		text-align: center;
		user-select: none;
		float: left;
	}
	.customize .sec-mid .bl-previous .el-text {
		line-height: 1;
		position: relative;
		color: #7e7e7e;
		font-size: 13px;
		font-weight: bold;
		padding-right: 80px;
	}
	.customize .sec-mid .bl-previous .el-text::after {
		content: "";
		display: inline-block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 17px 0 17px 22px;
		border-color: transparent transparent transparent #c0191f;
		margin: 0 auto;
		transform: rotate(180deg);
	}
	.customize .sec-mid .ly-select {
		display: none;
		background-color: #fff;
		padding: 50px 4% 200px;
		box-sizing: border-box;
	}
	.customize .sec-mid .ly-select.active {
		display: block;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow-y: auto;
		z-index: 2;
	}
	.customize .sec-mid .ly-select .bl-select {
		color: #3d3d3d;
		width: 100%;
		-webkit-appearance: none;
		height: 42px;
		box-sizing: border-box;
		border: 1px solid #cbcbcb;
		padding: 0 15px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.customize .sec-mid .ly-select .wrap {
		margin: 30px auto;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		box-sizing: border-box;
	}
	.customize .sec-mid .ly-select .wrap .box {
		position: relative;
		width: 48%;
		margin-bottom: 4%;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img {
		position: relative;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.6;
		z-index: 4;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%) rotate(45deg);
		-webkit-transform: translateY(-50%) translateX(-50%) rotate(45deg);
		border-right: 8px solid #e6002e;
		border-bottom: 8px solid #e6002e;
		display: block;
		height: 30px;
		width: 15px;
		z-index: 5;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-img {
		display: block;
		text-align: center;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-img .el-img {
	}
	.customize .sec-mid .ly-select .wrap .box .el-title {
		font-size: 12px;
		margin: 5px auto;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn {
		display: inline-block;
		margin: 0 auto 5px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn .el-btn {
		display: block;
		padding: 0 15px 0 27px;
		background-image: url(../assets/images/common/icon-search.svg);
		background-position: 13px center;
		background-repeat: no-repeat;
		background-size: 10px 10px;
		font-size: 12px;
		color: var(--main);
		font-weight: bold;
		border: 1px solid var(--main);
		border-radius: 10px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn .el-btn:hover {
		color: #fff;
		background-color: var(--main);
		background-image: url(../assets/images/common/icon-search-hover.svg);
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like {
		float: right;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like .el-like {
		display: block;
		width: 24px;
		height: 20px;
		background: url(../assets/images/common/icon-heart.svg) center center no-repeat;
		background-size: 24px 20px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like .el-like:hover {
		background: url(../assets/images/common/icon-heart-hover.svg) center center no-repeat;
	}
	.customize .sec-bottom {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		background-color: #f5f5f5;
		z-index: 2;
	}
	.customize .sec-bottom .inner {
		max-width: none;
		width: 100%;
	}
	.customize .sec-bottom .ly-list {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: 100%;
		overflow-x: auto;
		background-color: #fff;
		border-top: 1px solid #878787;
		border-bottom: 1px solid #878787;
		box-sizing: border-box;
		padding: 10px;
	}
	.customize .sec-bottom .ly-list .box {
		display: block;
		margin: 0 10px 0 0;
	}
	.customize .sec-bottom .ly-list .box .bl-img {
		position: relative;
		display: none;
		width: 150px;
		background-color: #e4e4e4;
		box-sizing: border-box;
	}
	.customize .sec-bottom .ly-list .box .bl-img .el-img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.customize .sec-bottom .ly-list .box.active .el-title {
		color: #fff;
		background-color: var(--main);
		border-color: var(--main);
	}
	.customize .sec-bottom .ly-list .box .el-title {
		font-weight: bold;
		margin: 5px auto;
		width: 135px;
		text-align: center;
		background-color: #fff;
		border: 1px solid #878787;
		color: #878787;
		padding: 5px 0;
		border-radius: 15px;
		box-sizing: border-box;
	}
	.customize .sec-bottom .ly-btn {
		padding: 15px 0 20px;
	}
	.customize .sec-bottom .ly-btn .bl-btn {
	}
	.customize .sec-bottom .ly-btn .bl-btn .el-btn {
		width: 250px;
		margin: 0 auto;
		display: block;
		background-color: var(--main);
		color: #fff;
		padding: 10px 0;
		font-size: 16px;
		text-align: center;
		border-radius: 30px;
		font-weight: bold;
	}
	.customize .sec-bottom .ly-btn .bl-btn .el-btn:hover {
		color: #fff;
		background-color: var(--main);
	}
	.customize .sec-bottom .ly-btn .el-text {
		text-align: center;
		display: none;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar,
	.customize .sec-bottom .ly-list::-webkit-scrollbar {
		width: 20px;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar-track,
	.customize .sec-bottom .ly-list::-webkit-scrollbar-track {
		background-color: #eee;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar-thumb,
	.customize .sec-bottom .ly-list::-webkit-scrollbar-thumb {
		background-color: #ccc;
		border-radius: 5px;
	}
	.customize .sec-mid .inner .sp-box {
		padding: 0 0 220px;
	}
	.customize .sec-mid .inner .sp-box .el-desc {
	}
	.customize .sec-mid .inner .sp-box .el-title {
		font-weight: bold;
		font-size: 20px;
		margin-top: 50px;
	}
	.customize .sec-mid .inner .sp-box .ly-selected {
		counter-reset: month;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		margin-bottom: 30px;
		counter-increment: month;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box > div {
		width: 48%;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box .bl-img {
		position: relative;
		text-align: center;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box .bl-img::before {
		// content: counter(month);
		position: absolute;
		top: -13px;
		left: -13px;
		width: 30px;
		height: 30px;
		// background-color: #333;
		color: #fff;
		border-radius: 50%;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box .bl-btn {
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box .bl-btn .el-btn {
		display: block;
		font-weight: bold;
		border: 1px solid #707070;
		color: #707070;
		text-align: center;
		background-color: #fff;
		padding: 10px 0;
		box-sizing: border-box;
		border-radius: 25px;
	}
	.customize .sec-mid .inner .sp-box .ly-selected .box .bl-btn .el-btn .el-icon {
		width: 20px;
		vertical-align: text-top;
		margin-right: 7px;
	}
	.customize.poster .sec-mid .bl-next {
		display: none;
	}
	.customize .sec-naming {
		min-height: calc(100vh - 80px);
		background-color: #e4e4e4;
		padding: 30px 0 112px;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner {
		max-width: 640px;
		width: 90%;
		margin: 0 auto;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box {
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box .el-title {
		font-size: 16px;
		font-weight: bold;
		color: #7e7e7e;
	}
	.customize .sec-naming .inner .box .el-textbox {
		color: #3d3d3d;
		max-width: 500px;
		width: 100%;
		-webkit-appearance: none;
		height: 42px;
		border: 1px solid #cbcbcb;
		padding: 0 15px;
		background-color: #fff;
		border-radius: 13px;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box .el-caution {
		color: #7e7e7e;
	}
	.customize .sec-naming .inner .box .bl-btn {
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100%;
		text-align: center;
		padding: 15px 0 20px;
		background-color: #fff;
		border-top: 1px solid #878787;
		background-color: #f5f5f5;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box .bl-btn .el-btn {
		max-width: 300px;
		width: 90%;
		margin: 0 auto;
		display: block;
		background-color: var(--main);
		color: #fff;
		padding: 10px 0;
		font-size: 16px;
		text-align: center;
		border-radius: 30px;
		font-weight: bold;
	}
	.customize .sec-naming .inner .box .bl-btn .el-icon {
		width: 24px;
		vertical-align: text-top;
	}
	.customize .sec-naming .inner .box .bl-img {
		text-align: center;
	}
	.customize .sec-naming .inner .box .bl-img .el-img {
	}
	.customize .sec-completion {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #040404;
		overflow-y: auto;
	}
	.customize .sec-completion .inner {
		width: 90%;
		margin: 5% auto;
		padding: 15px 0;
		background-color: #fff;
	}
	.customize .sec-completion .inner .bl-title {
		border-bottom: 1px solid #bebebe;
	}
	.customize .sec-completion .inner .bl-title .el-title {
		text-align: center;
		color: #3d3d3d;
		font-size: 24px;
		font-weight: bold;
	}
	.customize .sec-completion .inner .wrap {
		padding: 30px 0;
	}
	.customize .sec-completion .inner .wrap .box {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		max-width: 640px;
		width: 90%;
		margin: 15px auto;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-img {
		width: 25%;
	}
	.customize .sec-completion .inner .wrap .box .bl-img .el-img {
	}
	.customize .sec-completion .inner .wrap .box .bl-desc {
		width: 70%;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-title {
		color: #707070;
		font-size: 18px;
		margin: 0;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-date {
		display: inline-block;
		color: #707070;
		background-color: #f3f3f3;
		padding: 5px 15px;
		font-size: 12px;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-date .el-icon {
		width: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn {
		text-align: right;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn {
		display: inline-block;
		border-width: 1px;
		border-style: solid;
		padding: 3px 15px;
		border-radius: 14px;
		margin: 5px;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.preview {
		color: var(--main);
		border-color: var(--main);
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.preview .el-icon {
		width: 14px;
		vertical-align: text-top;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.modify {
		color: #707070;
		border-color: #707070;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.modify .el-icon {
		width: 17px;
		vertical-align: text-top;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn:hover {
		background-color: #f3f3f3;
	}
	.customize .sec-completion .inner .bl-bottom {
		text-align: center;
		padding: 50px 0;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn {
		display: inline-block;
		width: 300px;
		margin: 10px;
		padding: 15px 0;
		border-radius: 30px;
		font-size: 22px;
		font-weight: bold;
		border: 1px solid transparent;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:nth-child(1) {
		color: #707070;
		background-color: #f3f3f3;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:nth-child(2) {
		color: #fff;
		background-color: var(--main);
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:hover {
		opacity: 0.8;
	}
}
@media screen and (max-width: 420px) {
	.customize .sec-top {
		height: 50px;
	}
	.customize .sec-top .el-text {
		font-size: 10px;
		margin-left: 55px;
		width: calc(100% - 110px);
		line-height: 1.2;
	}
	.customize .sec-top .bl-btn .el-btn {
		height: 42px;
		width: 50px;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding: 0;
		text-align: center;
	}
	.customize .sec-top .bl-btn.goback .el-btn {
		font-size: 12px;
		padding: 0;
	}
	.customize .sec-completion .inner .bl-title .el-title {
		font-size: 16px;
		width: 90%;
		margin: 1em auto;
	}
	.customize .sec-completion .inner .wrap {
		padding: 15px 0;
	}
	.customize .sec-completion .inner .wrap .box {
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		padding-bottom: 15px;
		border-bottom: 1px solid #ccc;
	}
	.customize .sec-completion .inner .wrap .box:last-child {
		border-bottom: 0;
	}
	.customize .sec-completion .inner .wrap .box .bl-img {
		width: 100%;
		text-align: center;
	}
	.customize .sec-completion .inner .wrap .box .bl-img .el-img {
		height: 160px;
		object-fit: contain;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc {
		width: 100%;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-title {
		font-size: 14px;
		margin: 15px auto;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn {
		text-align: center;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn {
		font-size: 12px;
	}
	.customize .sec-completion .inner .bl-bottom {
		padding: 15px 0;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn {
		width: 45%;
		font-size: 12px;
		margin: 0;
	}
}
/* --- PC ---------- */
@media screen and (min-width: 1025px) {
	main.customize {
		padding: 0;
	}
	main.customize section {
		padding: 0;
	}
	.customize .sec-top {
		border-top: 8px solid var(--main);
		background-color: #f5f5f5;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		height: 80px;
		box-sizing: border-box;
	}
	.customize .sec-top .el-text {
		font-weight: bold;
		font-size: 16px;
		margin-left: 120px;
	}
	.customize .sec-top .bl-btn {
		position: absolute;
		right: 0;
		bottom: 0;
	}
	.customize .sec-top .bl-btn .el-btn {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		padding: 0 60px;
		text-align: center;
		height: 72px;
		background-color: #333;
		color: #fff;
		font-size: 16px;
		box-sizing: border-box;
	}
	.customize .sec-top .bl-btn .el-btn:hover {
		background-color: #000;
	}
	.customize .sec-top .bl-btn.goback {
		right: auto;
		left: 0;
	}
	.customize .sec-top .bl-btn.goback .el-btn {
		padding: 0 30px;
	}
	.customize .sec-mid {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		min-height: calc(100vh - 80px - 214px);
		background-color: #e4e4e4;
		padding-bottom: 1%;
	}
	.customize .sec-mid .inner {
		max-width: none;
		margin: 5px 0 5px auto;
		width: 95%;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		box-sizing: border-box;
	}
	.customize .sec-mid .inner .sp-box {
		display: none;
	}
	.customize .sec-mid .ly-view {
		position: relative;
		width: 45%;
		align-self: center;
	}
	.customize .sec-mid .ly-view .el-title {
		font-size: 18px;
		font-weight: bold;
		color: #7e7e7e;
	}
	.customize .sec-mid .ly-view .bl-calendar {
	}
	.customize .sec-mid .ly-view .bl-calendar .el-calendar {
		width: 100%;
	}
	.customize .sec-mid .bl-next {
		width: 10%;
		text-align: center;
		user-select: none;
		display: table;
	}
	.customize .sec-mid .bl-next .el-text {
		line-height: 3;
		position: relative;
		color: #7e7e7e;
		font-size: 13px;
		font-weight: bold;
		display: table-cell;
		vertical-align: middle;
	}
	.customize .sec-mid .bl-next .el-text::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 17px 0 17px 22px;
		border-color: transparent transparent transparent #c0191f;
		margin: 0 auto;
	}
	.customize .sec-mid .bl-previous {
		width: 10%;
		text-align: center;
		user-select: none;
		display: table;
	}
	.customize .sec-mid .bl-previous .el-text {
		line-height: 3;
		position: relative;
		color: #7e7e7e;
		font-size: 13px;
		font-weight: bold;
		display: table-cell;
		vertical-align: middle;
	}
	.customize .sec-mid .bl-previous .el-text::after {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 17px 0 17px 22px;
		border-color: transparent transparent transparent #c0191f;
		margin: 0 auto;
		transform: rotate(180deg);
	}
	.customize .sec-mid .ly-select {
		width: 45%;
		background-color: #fff;
		padding: 3%;
		box-sizing: border-box;
		align-self: center;
	}
	.customize .sec-mid .ly-select .bl-select {
		color: #3d3d3d;
		width: 100%;
		-webkit-appearance: none;
		height: 42px;
		box-sizing: border-box;
		border: 1px solid #cbcbcb;
		padding: 0 15px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.customize .sec-mid .ly-select .wrap {
		margin: 30px auto;
		height: 450px;
		padding-right: 3.5%;
		overflow-y: auto;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		box-sizing: border-box;
	}
	.customize .sec-mid .ly-select .wrap .box {
		position: relative;
		width: 31%;
		margin: 0 3.5% 3.5% 0;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img {
		position: relative;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		opacity: 0.6;
		z-index: 1;
	}
	.customize .sec-mid .ly-select .wrap .box.active .bl-img::after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%) rotate(45deg);
		-webkit-transform: translateY(-50%) translateX(-50%) rotate(45deg);
		border-right: 8px solid #e6002e;
		border-bottom: 8px solid #e6002e;
		display: block;
		height: 30px;
		width: 15px;
		z-index: 2;
	}
	.customize .sec-mid .ly-select .wrap .box:nth-of-type(3n) {
		margin: 0 0 3.5% 0;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-img {
		display: block;
		text-align: center;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-img .el-img {
	}
	.customize .sec-mid .ly-select .wrap .box .el-title {
		font-size: 12px;
		margin: 5px auto;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn {
		display: inline-block;
		margin: 0 auto 5px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn .el-btn {
		display: block;
		padding: 0 15px 0 27px;
		background-image: url(../assets/images/common/icon-search.svg);
		background-position: 13px center;
		background-repeat: no-repeat;
		background-size: 10px 10px;
		font-size: 12px;
		color: var(--main);
		font-weight: bold;
		border: 1px solid var(--main);
		border-radius: 10px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-btn .el-btn:hover {
		color: #fff;
		background-color: var(--main);
		background-image: url(../assets/images/common/icon-search-hover.svg);
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like {
		float: right;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like .el-like {
		display: block;
		width: 24px;
		height: 20px;
		background: url(../assets/images/common/icon-heart.svg) center center no-repeat;
		background-size: 24px 20px;
	}
	.customize .sec-mid .ly-select .wrap .box .bl-like .el-like:hover {
		background: url(../assets/images/common/icon-heart-hover.svg) center center no-repeat;
	}
	.customize .sec-bottom {
		padding: 30px 0;
		background-color: #f5f5f5;
	}
	.customize .sec-bottom .inner {
		max-width: none;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.customize .sec-bottom .ly-list {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		width: calc(100% - 350px);
		overflow-x: auto;
	}
	.customize .sec-bottom .ly-list .box {
		display: block;
		margin: 0 20px 0 0;
	}
	.customize .sec-bottom .ly-list .box .bl-img {
		position: relative;
		display: block;
		width: 150px;
		// padding-top: 100px;
		background-color: #e4e4e4;
		box-sizing: border-box;
	}
	.customize .sec-bottom .ly-list .box .bl-img .el-img {
		position: absolute;
		top: 0;
		left: 0;
		object-fit: contain;
		max-width: none;
		width: 100%;
		height: 100%;
		box-sizing: border-box;
	}
	.customize .sec-bottom .ly-list .box.active .bl-img {
		border: 3px solid var(--main);
		background-color: #fff;
	}
	.customize .sec-bottom .ly-list .box.active .bl-img::after {
		content: "選択中";
		display: inline-block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translateY(-50%) translateX(-50%);
		-webkit-transform: translateY(-50%) translateX(-50%);
		color: #ffffff;
		font-size: 16px;
	}
	.customize .sec-bottom .ly-list .box .el-title {
		font-weight: bold;
		margin: 5px auto;
	}
	.customize .sec-bottom .ly-btn {
	}
	.customize .sec-bottom .ly-btn .bl-btn {
	}
	.customize .sec-bottom .ly-btn .bl-btn .el-btn {
		width: 300px;
		display: block;
		background-color: var(--main);
		color: #fff;
		padding: 15px 0;
		font-size: 20px;
		text-align: center;
		border-radius: 30px;
	}
	.customize .sec-bottom .ly-btn .bl-btn .el-btn:hover {
		color: #fff;
		background-color: var(--main);
	}
	.customize .sec-bottom .ly-btn .el-text {
		text-align: center;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar,
	.customize .sec-bottom .ly-list::-webkit-scrollbar {
		width: 20px;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar-track,
	.customize .sec-bottom .ly-list::-webkit-scrollbar-track {
		background-color: #eee;
	}
	.customize .sec-mid .ly-select .wrap::-webkit-scrollbar-thumb,
	.customize .sec-bottom .ly-list::-webkit-scrollbar-thumb {
		background-color: #ccc;
		border-radius: 5px;
	}
	.customize.poster .sec-mid .bl-next {
		display: none;
	}
	.customize .sec-naming {
		min-height: calc(100vh - 80px);
		background-color: #e4e4e4;
		padding: 100px 0;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner {
		max-width: 1400px;
		width: 90%;
		margin: 0 auto;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box {
		width: 45%;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box .el-title {
		font-size: 18px;
		font-weight: bold;
		color: #7e7e7e;
	}
	.customize .sec-naming .inner .box .el-textbox {
		color: #3d3d3d;
		max-width: 500px;
		width: 100%;
		-webkit-appearance: none;
		height: 42px;
		border: 1px solid #cbcbcb;
		padding: 0 15px;
		background-color: #fff;
		border-radius: 13px;
		box-sizing: border-box;
	}
	.customize .sec-naming .inner .box .el-caution {
		color: #7e7e7e;
	}
	.customize .sec-naming .inner .box .bl-btn {
		margin-top: 20%;
	}
	.customize .sec-naming .inner .box .bl-btn .el-btn {
		width: 300px;
		display: block;
		background-color: var(--main);
		color: #fff;
		padding: 15px 0;
		font-size: 20px;
		text-align: center;
		border-radius: 30px;
	}
	.customize .sec-naming .inner .box .bl-btn .el-icon {
		width: 24px;
		vertical-align: text-top;
	}
	.customize .sec-naming .inner .box .bl-img {
		text-align: center;
	}
	.customize .sec-naming .inner .box .bl-img .el-img {
	}
	.customize .sec-completion {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #040404;
		overflow-y: auto;
	}
	.customize .sec-completion .inner {
		max-width: 940px;
		width: 90%;
		margin: 5% auto;
		background-color: #fff;
		padding: 15px 0;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .bl-title {
		border-bottom: 1px solid #bebebe;
	}
	.customize .sec-completion .inner .bl-title .el-title {
		text-align: center;
		color: #3d3d3d;
		font-size: 24px;
		font-weight: bold;
	}
	.customize .sec-completion .inner .wrap {
		padding: 30px 0;
	}
	.customize .sec-completion .inner .wrap .box {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		max-width: 640px;
		width: 90%;
		margin: 15px auto;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-img {
		width: 25%;
	}
	.customize .sec-completion .inner .wrap .box .bl-img .el-img {
	}
	.customize .sec-completion .inner .wrap .box .bl-desc {
		width: 70%;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-title {
		color: #707070;
		font-size: 18px;
		margin: 0;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-date {
		display: inline-block;
		color: #707070;
		background-color: #f3f3f3;
		padding: 5px 15px;
		font-size: 12px;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .el-date .el-icon {
		width: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn {
		text-align: right;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn {
		display: inline-block;
		border-width: 1px;
		border-style: solid;
		padding: 3px 15px;
		border-radius: 14px;
		margin: 5px;
		font-weight: bold;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.preview {
		color: var(--main);
		border-color: var(--main);
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.preview .el-icon {
		width: 14px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.modify {
		color: #707070;
		border-color: #707070;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn.modify .el-icon {
		width: 17px;
		vertical-align: text-top;
		margin-right: 5px;
	}
	.customize .sec-completion .inner .wrap .box .bl-desc .bl-btn .el-btn:hover {
		background-color: #f3f3f3;
	}
	.customize .sec-completion .inner .bl-bottom {
		text-align: center;
		padding: 80px 0;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn {
		display: inline-block;
		width: 300px;
		margin: 0 10px;
		padding: 15px 0;
		border-radius: 30px;
		font-size: 18px;
		font-weight: bold;
		border: 1px solid transparent;
		box-sizing: border-box;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:nth-child(1) {
		color: #707070;
		background-color: #f3f3f3;
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:nth-child(2) {
		color: #fff;
		background-color: var(--main);
	}
	.customize .sec-completion .inner .bl-bottom .el-btn:hover {
		opacity: 0.8;
	}
}
