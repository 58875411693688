::placeholder {
	color: #ccc;
}
/* --- SP ---------- */
@media screen and (max-width: 1024px) {
	.sec-progress {
	}
	.sec-progress .bl-title {
		margin: 8% auto;
	}
	.sec-progress .bl-title .el-title {
		font-size: 23px;
		color: #3d3d3d;
		font-weight: bold;
		text-align: center;
	}
	.sec-progress .ly-progress {
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.sec-progress .ly-progress .box {
		position: relative;
		width: 25%;
		background-color: #f3f3f3;
		height: 30px;
		box-sizing: border-box;
		display: table;
	}
	.sec-progress .ly-progress .box .el-text {
		display: table-cell;
		font-size: 10px;
		text-align: center;
		vertical-align: middle;
		width: 100%;
		margin: 0;
	}
	.sec-progress .ly-progress .box.active {
		background-color: var(--main);
		z-index: 1;
	}
	.sec-progress .ly-progress .box.active::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 0 15px 8px;
		border-color: transparent transparent transparent #f3f3f3;
	}
	.sec-progress .ly-progress .box.active:first-child::before {
		content: none;
	}
	.sec-progress .ly-progress .box.active::after {
		content: "";
		position: absolute;
		top: 0;
		right: -8px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 15px 0 15px 8px;
		border-color: transparent transparent transparent var(--main);
	}
	.sec-progress .ly-progress .box.active:last-child::after {
		content: none;
	}
	.sec-progress .ly-progress .box.active .el-text {
		color: #fff;
	}
	.sec-cart {
		padding: 0;
	}
	.sec-cart .ly-list {
		border-top: 2px solid #000000;
		padding: 15px 0;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box {
		margin: 15px auto;
		border-bottom: 1px solid #ccc;
		padding-bottom: 15px;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box:last-of-type {
		border: 0;
	}
	.sec-cart .ly-list .box .bl-img {
	}
	.sec-cart .ly-list .box .bl-img a {
	}
	.sec-cart .ly-list .box .bl-img .el-img {
		object-fit: contain;
	}
	.sec-cart .ly-list .box .bl-img .el-date {
		display: inline-block;
		color: #707070;
		background-color: #f3f3f3;
		padding: 2px 13px;
		font-size: 12px;
		font-weight: bold;
		box-sizing: border-box;
		margin: 5px auto;
	}
	.sec-cart .ly-list .box .bl-img .el-date .el-icon {
		width: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-desc {
		box-sizing: border-box;
		text-align: right;
		margin: 20px auto;
	}
	.sec-cart .ly-list .box .bl-desc .el-text {
		font-size: 16px;
		font-weight: bold;
		margin: 0;
	}
	.sec-cart .ly-list .box .bl-desc .el-delivery {
		color: #8b8b8b;
		font-size: 16px;
	}
	.sec-cart .ly-list .box .bl-desc .el-amount {
		-webkit-appearance: none;
		border: 1px solid #b6b6b6;
		width: 118px;
		height: 40px;
		box-sizing: border-box;
		font-size: 18px;
		font-weight: bold;
		padding: 0 20px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.sec-cart .ly-list .box .bl-btn {
		width: 100%;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn {
		display: block;
		width: 49%;
		border-width: 1px;
		border-style: solid;
		padding: 9px 15px;
		border-radius: 24px;
		margin: 10px 0;
		font-weight: bold;
		box-sizing: border-box;
		font-size: 16px;
		text-align: center;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn .el-icon {
		width: 19px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn.preview {
		color: var(--main);
		border-color: var(--main);
	}
	.sec-cart .ly-list .box .bl-btn .el-btn.modify {
		color: #707070;
		border-color: #707070;
	}
	.sec-cart .ly-list .box .bl-btn .el-delete {
		display: block;
		width: 100%;
		margin: 30px auto;
		font-size: 14px;
		color: #707070;
		text-align: center;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box .bl-btn .el-delete .el-icon {
		width: 19px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-btn .el-price {
		width: 100%;
		margin: 0;
		text-align: right;
		font-weight: bold;
		font-size: 16px;
		margin: 0;
	}
	.sec-cart .small {
		font-size: 16px;
		font-weight: normal;
		margin: 0;
	}
	.sec-cart .ly-list .box .bl-btn .small {
		width: 100%;
		font-size: 12px;
		text-align: right;
	}
	.sec-cart .ly-total {
		border-top: 2px solid #000000;
		padding: 5% 0;
		box-sizing: border-box;
	}
	.sec-cart .ly-total dl {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		text-align: right;
	}
	.sec-cart .ly-total dl dt {
		font-size: 16px;
		width: calc(100% - 200px);
	}
	.sec-cart .ly-total dl dd {
		width: 200px;
		font-size: 20px;
		margin: 0;
		box-sizing: border-box;
	}
	.sec-cart .ly-total dl:last-of-type dt {
		font-weight: bold;
	}
	.sec-cart .ly-total dl:last-of-type dd {
		font-weight: bold;
		font-size: 22px;
	}
	.sec-login .inner {
	}
	.sec-login .ly-login {
		background-color: #f3f3f3;
		padding: 5% 0;
		border-radius: 3px;
		margin-bottom: 30px;
		box-sizing: border-box;
	}
	.sec-login .bl-title {
	}
	.sec-login .bl-title .el-title {
		margin-top: 0;
		font-size: 18px;
		text-align: center;
		font-weight: bold;
	}
	.sec-login .ly-login .bl-form {
		max-width: 515px;
		width: 90%;
		margin: 0 auto;
	}
	.sec-login .ly-login .bl-form dl {
		margin: 15px auto;
	}
	.sec-login .ly-login .bl-form dl dt {
		margin: 0;
	}
	.sec-login .ly-login .bl-form dl dt .el-name {
		font-weight: bold;
		font-size: 14px;
		margin: 5px auto;
	}
	.sec-login .ly-login .bl-form dl dd {
		margin: 0;
	}
	.sec-login .ly-login .bl-form dl .el-textbox {
		width: 100%;
		-webkit-appearance: none;
		height: 45px;
		box-sizing: border-box;
		border: 1px solid #c8c8c8;
		padding: 0 15px;
		font-size: 16px;
	}
	.sec-login .ly-login .bl-form .el-submit {
		display: block;
		-webkit-appearance: none;
		border: 0;
		width: 260px;
		margin: 30px auto;
		text-align: center;
		background-color: var(--main);
		padding: 15px 0;
		border-radius: 5px;
		color: #fff;
		font-size: 14px;
		cursor: pointer;
		font-weight: bold;
		box-sizing: border-box;
	}
	.sec-login .ly-signup {
		background-color: #f3f3f3;
		padding: 5% 0;
		border-radius: 3px;
		box-sizing: border-box;
	}
	.sec-login .ly-signup .bl-btn {
		max-width: 340px;
		width: 90%;
		margin: 0 auto;
	}
	.sec-login .ly-signup .bl-btn .el-btn {
		display: block;
		width: 100%;
		font-size: 14px;
		font-weight: bold;
	}
	.sec-login .ly-signup .bl-btn .el-text {
	}
	.ship .sec-ship {
		padding-top: 0;
	}
	.ship .sec-ship .bl-title {
	}
	.ship .sec-ship .bl-title .el-title {
		font-size: 18px;
		font-weight: bold;
		border-top: 2px solid #000000;
		padding: 10px 15px;
		background-color: #f3f3f3;
		box-sizing: border-box;
		margin: 50px 0 0;
	}
	.ship .sec-ship .bl-title:first-of-type .el-title {
		margin: 0;
	}
	.ship .sec-ship .bl-form {
	}
	.ship .sec-ship .bl-form .el-error {
		margin: 0;
		color: #e6002e;
	}
	.ship .sec-ship .bl-form dl {
	}
	.ship .sec-ship .bl-form dl dt {
		margin: 5px auto;
	}
	.ship .sec-ship .bl-form dl dt p {
		margin: 0;
	}
	.ship .sec-ship .bl-form dl dt .el-name {
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
	}
	.ship .sec-ship .bl-form dl dt .el-require {
		padding-left: 7px;
		display: inline-block;
		background-color: #fe0636;
		color: #fff;
		font-size: 12px;
		padding: 1px 7px;
		font-weight: 400;
	}
	.ship .sec-ship .bl-form dl dd {
		margin: 0;
	}
	.ship .sec-ship .bl-form dl dd .el-textbox {
		width: 100%;
		-webkit-appearance: none;
		height: 45px;
		box-sizing: border-box;
		border: 1px solid #c8c8c8;
		padding: 0 15px;
		font-size: 16px;
	}
	.ship .sec-ship .bl-form dl dd select {
		-webkit-appearance: none;
		border: 1px solid #b6b6b6;
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		font-size: 16px;
		padding: 0 45px 0 20px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.ship .sec-ship .bl-form .wrap-name {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.ship .sec-ship .bl-form .wrap-name dl {
		width: 48%;
	}
	.ship .sec-ship .bl-form .el-postnum dd .el-textbox {
		width: 150px;
	}
	.ship .sec-ship .bl-form .wrap-address {
	}
	.ship .sec-ship .bl-form .wrap-address dd select {
		width: 250px;
	}
	.ship .sec-ship .wrap-radio {
	}
	.ship .sec-ship .wrap-radio .box {
	}
	.ship .sec-ship .wrap-radio .box input[type="radio"] {
		opacity: 0;
		position: absolute;
		-webkit-appearance: none;
	}
	.ship .sec-ship .wrap-radio .box .el-radio {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		padding: 1em 15px 1em 30px;
		position: relative;
		width: auto;
		font-weight: bold;
		font-size: 16px;
	}
	.ship .sec-ship .wrap-radio .box .el-radio::before {
		background: #fff;
		border: 1px solid #c8c8c8;
		border-radius: 50%;
		content: "";
		display: block;
		height: 16px;
		left: 5px;
		margin-top: -8px;
		position: absolute;
		top: 50%;
		width: 16px;
	}
	.ship .sec-ship .wrap-radio .box .el-radio::after {
		background: #e6002e;
		border-radius: 50%;
		content: "";
		display: block;
		height: 10px;
		left: 9px;
		margin-top: -4px;
		opacity: 0;
		position: absolute;
		top: 50%;
		width: 10px;
	}
	.ship .sec-ship .wrap-radio .box input[type="radio"]:checked + .el-radio:after {
		opacity: 1;
	}
	.ship .sec-ship .wrap-textarea {
	}
	.ship .sec-ship .wrap-textarea textarea {
		margin: 1em auto;
		border: 1px solid #c8c8c8;
		box-sizing: border-box;
		padding: 15px;
		font-size: 16px;
		width: 100%;
		height: 150px;
		line-height: 1.7;
		resize: none;
	}
	.ship .sec-ship .bl-title .el-title.policy {
		font-weight: bold;
		border-top: 0;
		padding: 0;
		background-color: transparent;
		box-sizing: border-box;
		margin: 50px 0 0;
	}
	.ship .sec-ship .ly-policy {
		border: 1px solid #c8c8c8;
		box-sizing: border-box;
		padding: 15px;
		margin: 1em auto;
	}
	.ship .sec-ship .ly-policy .wrap {
		height: 250px;
		overflow-y: auto;
	}
	.ship .sec-ship .ly-policy .wrap::-webkit-scrollbar {
		width: 20px;
	}
	.ship .sec-ship .ly-policy .wrap::-webkit-scrollbar-thumb {
		background-color: #ccc;
	}
	.ship .sec-ship .bl-agree {
		text-align: center;
	}
	.ship .sec-ship .bl-agree input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		-webkit-appearance: none;
	}
	.ship .sec-ship .bl-agree .el-checkbox {
		display: inline-block;
		box-sizing: border-box;
		cursor: pointer;
		padding: 0 0 0 30px;
		margin-right: 15px;
		position: relative;
		font-size: 14px;
	}
	.ship .sec-ship .bl-agree .el-checkbox::before {
		background: #fff;
		border: 1px solid #b1b1b1;
		content: "";
		display: block;
		height: 16px;
		left: 5px;
		margin-top: -8px;
		position: absolute;
		top: 50%;
		width: 16px;
	}
	.ship .sec-ship .bl-agree .el-checkbox::after {
		border-right: 3px solid #e6002e;
		border-bottom: 3px solid #e6002e;
		content: "";
		display: block;
		height: 9px;
		left: 10px;
		margin-top: -7px;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: rotate(45deg);
		width: 5px;
	}
	.ship .sec-ship .bl-agree input[type="checkbox"]:checked + .el-checkbox:after {
		opacity: 1;
	}
	.ship .sec-ship .bl-btn {
		text-align: center;
		margin: 30px auto;
	}
	.ship .sec-ship .bl-desc {
		margin: 1em auto;
	}
	.ship .sec-ship .bl-desc .el-confirm {
		margin: 0;
		font-size: 16px;
	}
	.confirm .sec-cart .ly-list .box {
		position: relative;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.confirm .sec-cart .ly-list .box .bl-desc {
	}
	.confirm .sec-cart .ly-list .box .bl-btn {
	}
	.confirm .sec-cart .ly-list .box .bl-btn .el-price {
		margin: 0;
	}
	.confirm .sec-cart .ly-total .bl-btn {
		text-align: right;
	}
	.confirm .sec-cart .ly-total .bl-btn .el-btn {
		background-color: #8b8b8b;
		border-color: #8b8b8b;
	}
	.confirm .sec-ship .bl-modify {
		text-align: right;
	}
	.confirm .sec-ship .bl-modify .el-btn {
		background-color: #8b8b8b;
		border-color: #8b8b8b;
	}
	.sec-completion {
		padding-top: 0;
	}
	.sec-completion .inner {
		max-width: 900px;
	}
	.sec-completion .inner .el-title {
		text-align: center;
		font-weight: bold;
		font-size: 18px;
	}
	.sec-completion .inner .el-text {
	}
	.sec-completion .inner .el-text a {
		text-decoration: underline;
		text-underline-position: under;
	}
	.sec-qa .bl-title {
		margin: 30px auto;
	}
	.sec-qa .bl-title .el-title {
		text-align: center;
		font-weight: bold;
		font-size: 23px;
	}
	.sec-qa dl {
	}
	.sec-qa dl dt,
	.sec-qa dl dd {
		position: relative;
		padding: 15px 0 15px 45px;
		margin: 0;
	}
	.sec-qa dl dt::before,
	.sec-qa dl dd::before {
		position: absolute;
		top: 9px;
		left: 0;
		width: 35px;
		height: 35px;
		color: #fff;
		font-weight: bold;
		font-size: 16px;
		border-radius: 50%;
		text-align: center;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.sec-qa dl dt::before {
		content: "Q";
		background-color: var(--main);
	}
	.sec-qa dl dd::before {
		content: "A";
		background-color: #979797;
	}
	.sec-qa dl dt {
		font-weight: bold;
		font-size: 18px;
		border-bottom: 1px solid #ccc;
	}
	.sec-qa dl dd {
		font-size: 16px;
	}
	.sec-ship .address {
		padding: 10px 15px;
		box-sizing: border-box;
	}
	.sec-ship .address .box {
		font-size: 15px;
		vertical-align: text-top;
		margin-bottom: 10px;
	}
	.sec-ship .address .box .icon {
		margin-right: 8px;
	}
	.sec-ship .address .box img {
		vertical-align: baseline;
	}
	.car .btn01 {
		margin: 10px;
	}
	.add-btn {
	}
	.add-btn .btn {
		display: block;
		text-align: center;
		margin: 10px auto;
		padding: 12px 20px;
		background: #0b7ec3;
		color: #fff;
		border-radius: 5px;
	}
}
@media screen and (max-width: 420px) {
}
/* --- PC ---------- */
@media screen and (min-width: 1025px) {
	.sec-progress {
	}
	.sec-progress .bl-title {
		margin: 8% auto;
	}
	.sec-progress .bl-title .el-title {
		font-size: 30px;
		color: #3d3d3d;
		font-weight: bold;
		text-align: center;
	}
	.sec-progress .ly-progress {
		max-width: 900px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}
	.sec-progress .ly-progress .box {
		position: relative;
		width: 25%;
		background-color: #f3f3f3;
		height: 60px;
		box-sizing: border-box;
		display: table;
	}
	.sec-progress .ly-progress .box .el-text {
		display: table-cell;
		font-size: 20px;
		text-align: center;
		font-weight: bold;
		vertical-align: middle;
		width: 100%;
		margin: 0;
	}
	.sec-progress .ly-progress .box.active {
		background-color: var(--main);
		z-index: 1;
	}
	.sec-progress .ly-progress .box.active::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 30px 0 30px 22px;
		border-color: transparent transparent transparent #f3f3f3;
	}
	.sec-progress .ly-progress .box.active:first-child::before {
		content: none;
	}
	.sec-progress .ly-progress .box.active::after {
		content: "";
		position: absolute;
		top: 0;
		right: -22px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 30px 0 30px 22px;
		border-color: transparent transparent transparent var(--main);
	}
	.sec-progress .ly-progress .box.active:last-child::after {
		content: none;
	}
	.sec-progress .ly-progress .box.active .el-text {
		color: #fff;
	}
	.sec-cart {
		padding: 0;
	}
	.sec-cart .ly-list {
		border-top: 2px solid #000000;
		padding: 30px 0;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		margin: 30px auto 50px;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box .bl-img {
		width: 240px;
	}
	.sec-cart .ly-list .box .bl-img a {
	}
	.sec-cart .ly-list .box .bl-img .el-img {
		width: 240px;
		height: 240px;
		object-fit: contain;
	}
	.sec-cart .ly-list .box .bl-img .el-date {
		display: inline-block;
		color: #707070;
		background-color: #f3f3f3;
		padding: 5px 15px;
		font-size: 12px;
		font-weight: bold;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box .bl-img .el-date .el-icon {
		width: 20px;
		vertical-align: bottom;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-desc {
		width: calc(100% - 440px);
		padding: 0 3%;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box .bl-desc .el-text {
		font-size: 20px;
		font-weight: bold;
		margin: 0;
	}
	.sec-cart .ly-list .box .bl-desc .el-delivery {
		color: #8b8b8b;
		font-size: 16px;
	}
	.sec-cart .ly-list .box .bl-desc .el-amount {
		-webkit-appearance: none;
		border: 1px solid #b6b6b6;
		width: 118px;
		height: 54px;
		box-sizing: border-box;
		font-size: 24px;
		font-weight: bold;
		padding: 0 20px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.sec-cart .ly-list .box .bl-btn {
		width: 280px;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn {
		display: inline-block;
		width: 240px;
		border-width: 1px;
		border-style: solid;
		padding: 9px 15px;
		border-radius: 24px;
		margin-bottom: 10px;
		font-weight: bold;
		box-sizing: border-box;
		font-size: 22px;
		text-align: center;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn .el-icon {
		width: 19px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-btn .el-btn.preview {
		color: var(--main);
		border-color: var(--main);
	}
	.sec-cart .ly-list .box .bl-btn .el-btn.modify {
		color: #707070;
		border-color: #707070;
	}
	.sec-cart .ly-list .box .bl-btn .el-delete {
		display: block;
		font-size: 18px;
		color: #707070;
		text-align: center;
		margin: 30px auto;
		box-sizing: border-box;
	}
	.sec-cart .ly-list .box .bl-btn .el-delete .el-icon {
		width: 19px;
		vertical-align: middle;
		margin-right: 5px;
	}
	.sec-cart .ly-list .box .bl-btn .el-price {
		text-align: right;
		font-weight: bold;
		font-size: 32px;
		margin: 0;
	}
	.sec-cart .small {
		font-size: 20px;
		font-weight: normal;
		margin: 0;
	}
	.sec-cart .ly-list .box .bl-btn .small {
		text-align: right;
	}
	.sec-cart .ly-total {
		border-top: 2px solid #000000;
		padding: 5% 0;
		box-sizing: border-box;
	}
	.sec-cart .ly-total dl {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-align: flex-end;
		-ms-flex-align: flex-end;
		align-items: flex-end;
		text-align: right;
	}
	.sec-cart .ly-total dl dt {
		font-size: 18px;
	}
	.sec-cart .ly-total dl dd {
		width: 300px;
		font-size: 20px;
		box-sizing: border-box;
	}
	.sec-cart .ly-total dl:last-of-type dt {
		font-weight: bold;
	}
	.sec-cart .ly-total dl:last-of-type dd {
		font-weight: bold;
		font-size: 32px;
	}
	.sec-login .inner {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.sec-login .ly-login {
		width: calc(60% - 30px);
		background-color: #f3f3f3;
		padding: 5% 0;
		border-radius: 3px;
		box-sizing: border-box;
	}
	.sec-login .bl-title {
	}
	.sec-login .bl-title .el-title {
		margin-top: 0;
		font-size: 20px;
		text-align: center;
		font-weight: bold;
	}
	.sec-login .ly-login .bl-form {
		max-width: 515px;
		width: 90%;
		margin: 0 auto;
	}
	.sec-login .ly-login .bl-form dl {
		margin: 15px auto;
	}
	.sec-login .ly-login .bl-form dl dt {
		margin: 0;
	}
	.sec-login .ly-login .bl-form dl dt .el-name {
		font-weight: bold;
		font-size: 16px;
		margin: 5px auto;
	}
	.sec-login .ly-login .bl-form dl dd {
		margin: 0;
	}
	.sec-login .ly-login .bl-form dl .el-textbox {
		width: 100%;
		-webkit-appearance: none;
		height: 50px;
		box-sizing: border-box;
		border: 1px solid #c8c8c8;
		padding: 0 15px;
		font-size: 16px;
	}
	.sec-login .ly-login .bl-form .el-submit {
		display: block;
		-webkit-appearance: none;
		border: 0;
		width: 260px;
		margin: 30px auto;
		text-align: center;
		background-color: var(--main);
		padding: 15px 0;
		border-radius: 5px;
		color: #fff;
		font-size: 16px;
		cursor: pointer;
		font-weight: bold;
		box-sizing: border-box;
	}
	.sec-login .ly-signup {
		width: 40%;
		background-color: #f3f3f3;
		padding: 5% 0;
		border-radius: 3px;
		box-sizing: border-box;
	}
	.sec-login .ly-signup .bl-btn {
		max-width: 340px;
		width: 90%;
		margin: 0 auto;
	}
	.sec-login .ly-signup .bl-btn .el-btn {
		display: block;
		width: 100%;
		font-size: 16px;
		font-weight: bold;
	}
	.sec-login .ly-signup .bl-btn .el-text {
	}
	.ship .sec-ship {
		padding-top: 0;
	}
	.ship .sec-ship .bl-title {
	}
	.ship .sec-ship .bl-title .el-title {
		font-size: 24px;
		font-weight: bold;
		border-top: 2px solid #000000;
		padding: 15px 30px;
		background-color: #f3f3f3;
		box-sizing: border-box;
		margin: 50px 0 0;
	}
	.ship .sec-ship .bl-title:first-of-type .el-title {
		margin: 0;
	}
	.ship .sec-ship .bl-form {
	}
	.ship .sec-ship .bl-form .el-error {
		margin: 0;
		color: #e6002e;
	}
	.ship .sec-ship .bl-form dl {
	}
	.ship .sec-ship .bl-form dl dt {
		margin: 5px auto;
	}
	.ship .sec-ship .bl-form dl dt p {
		margin: 0;
	}
	.ship .sec-ship .bl-form dl dt .el-name {
		display: inline-block;
		font-size: 20px;
		font-weight: bold;
	}
	.ship .sec-ship .bl-form dl dt .el-require {
		padding-left: 7px;
		display: inline-block;
		background-color: #fe0636;
		color: #fff;
		font-size: 12px;
		padding: 1px 7px;
		font-weight: 400;
	}
	.ship .sec-ship .bl-form dl dd {
		margin: 0;
	}
	.ship .sec-ship .bl-form dl dd .el-textbox {
		width: 100%;
		-webkit-appearance: none;
		height: 50px;
		box-sizing: border-box;
		border: 1px solid #c8c8c8;
		padding: 0 15px;
		font-size: 16px;
	}
	.ship .sec-ship .bl-form dl dd select {
		-webkit-appearance: none;
		border: 1px solid #b6b6b6;
		width: 100%;
		height: 50px;
		box-sizing: border-box;
		font-size: 16px;
		padding: 0 45px 0 20px;
		background: url(../assets/images/common/icon-arrow.svg) calc(100% - 15px) center no-repeat;
		background-size: 16px 16px;
		background-color: #fff;
	}
	.ship .sec-ship .bl-form .wrap-name {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.ship .sec-ship .bl-form .wrap-name dl {
		width: 48%;
	}
	.ship .sec-ship .bl-form .el-postnum dd .el-textbox {
		width: 150px;
	}
	.ship .sec-ship .bl-form .wrap-address {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		max-width: 800px;
	}
	.ship .sec-ship .bl-form .wrap-address dl:nth-of-type(1) {
		width: 250px;
	}
	.ship .sec-ship .bl-form .wrap-address dl:nth-of-type(2) {
		width: calc(100% - 280px);
	}
	.ship .sec-ship .bl-form .wrap-address dd select {
		width: 250px;
	}
	.ship .sec-ship .wrap-radio {
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
	.ship .sec-ship .wrap-radio .box {
		width: 48%;
	}
	.ship .sec-ship .wrap-radio .box input[type="radio"] {
		opacity: 0;
		position: absolute;
		-webkit-appearance: none;
	}
	.ship .sec-ship .wrap-radio .box .el-radio {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		padding: 1em 30px;
		position: relative;
		width: auto;
		font-weight: bold;
		font-size: 18px;
	}
	.ship .sec-ship .wrap-radio .box .el-radio::before {
		background: #fff;
		border: 1px solid #c8c8c8;
		border-radius: 50%;
		content: "";
		display: block;
		height: 16px;
		left: 5px;
		margin-top: -8px;
		position: absolute;
		top: 50%;
		width: 16px;
	}
	.ship .sec-ship .wrap-radio .box .el-radio::after {
		background: #e6002e;
		border-radius: 50%;
		content: "";
		display: block;
		height: 10px;
		left: 9px;
		margin-top: -4px;
		opacity: 0;
		position: absolute;
		top: 50%;
		width: 10px;
	}
	.ship .sec-ship .wrap-radio .box input[type="radio"]:checked + .el-radio:after {
		opacity: 1;
	}
	.ship .sec-ship .wrap-textarea {
	}
	.ship .sec-ship .wrap-textarea textarea {
		margin: 1em auto;
		border: 1px solid #c8c8c8;
		box-sizing: border-box;
		padding: 15px;
		font-size: 16px;
		width: 100%;
		height: 150px;
		line-height: 1.7;
		resize: none;
	}
	.ship .sec-ship .bl-title .el-title.policy {
		font-size: 24px;
		font-weight: bold;
		border-top: 0;
		padding: 0;
		background-color: transparent;
		box-sizing: border-box;
		margin: 50px 0 0;
	}
	.ship .sec-ship .ly-policy {
		border: 1px solid #c8c8c8;
		box-sizing: border-box;
		padding: 30px;
		margin: 1em auto;
	}
	.ship .sec-ship .ly-policy .wrap {
		height: 250px;
		overflow-y: auto;
	}
	.ship .sec-ship .ly-policy .wrap::-webkit-scrollbar {
		width: 20px;
	}
	.ship .sec-ship .ly-policy .wrap::-webkit-scrollbar-thumb {
		background-color: #ccc;
	}
	.ship .sec-ship .bl-agree {
		text-align: center;
		margin: 50px auto 30px;
	}
	.ship .sec-ship .bl-agree input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		-webkit-appearance: none;
	}
	.ship .sec-ship .bl-agree .el-checkbox {
		display: inline-block;
		box-sizing: border-box;
		cursor: pointer;
		padding: 0 0 0 30px;
		margin-right: 15px;
		position: relative;
		font-size: 20px;
	}
	.ship .sec-ship .bl-agree .el-checkbox::before {
		background: #fff;
		border: 1px solid #b1b1b1;
		content: "";
		display: block;
		height: 16px;
		left: 5px;
		margin-top: -8px;
		position: absolute;
		top: 50%;
		width: 16px;
	}
	.ship .sec-ship .bl-agree .el-checkbox::after {
		border-right: 3px solid #e6002e;
		border-bottom: 3px solid #e6002e;
		content: "";
		display: block;
		height: 9px;
		left: 10px;
		margin-top: -7px;
		opacity: 0;
		position: absolute;
		top: 50%;
		transform: rotate(45deg);
		width: 5px;
	}
	.ship .sec-ship .bl-agree input[type="checkbox"]:checked + .el-checkbox:after {
		opacity: 1;
	}
	.ship .sec-ship .bl-btn {
		text-align: center;
		padding: 30px 0;
		margin: 30px auto;
	}
	.ship .sec-ship .bl-desc {
		margin: 1em auto;
	}
	.ship .sec-ship .bl-desc .el-confirm {
		margin: 0;
		font-size: 20px;
	}
	.confirm .sec-cart .ly-list .box {
		position: relative;
		-webkit-flex-wrap: wrap;
		flex-wrap: wrap;
	}
	.confirm .sec-cart .ly-list .box .bl-desc {
		width: calc(100% - 240px);
	}
	.confirm .sec-cart .ly-list .box .bl-btn {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.confirm .sec-cart .ly-list .box .bl-btn .el-price {
		margin: 0;
	}
	.confirm .sec-cart .ly-total .bl-btn {
		text-align: right;
	}
	.confirm .sec-cart .ly-total .bl-btn .el-btn {
		background-color: #8b8b8b;
		border-color: #8b8b8b;
	}
	.confirm .sec-ship .bl-modify {
		text-align: right;
	}
	.confirm .sec-ship .bl-modify .el-btn {
		background-color: #8b8b8b;
		border-color: #8b8b8b;
	}
	.sec-completion {
		padding-top: 0;
	}
	.sec-completion .inner {
		max-width: 900px;
	}
	.sec-completion .inner .el-title {
		text-align: center;
		font-weight: bold;
		font-size: 24px;
	}
	.sec-completion .inner .el-text {
		font-size: 20px;
	}
	.sec-completion .inner .el-text a {
		text-decoration: underline;
		text-underline-position: under;
	}
	.sec-qa .bl-title {
		margin: 8% auto;
	}
	.sec-qa .bl-title .el-title {
		text-align: center;
		font-weight: bold;
		font-size: 32px;
	}
	.sec-qa dl {
	}
	.sec-qa dl dt,
	.sec-qa dl dd {
		position: relative;
		padding: 30px 0 30px 60px;
		margin: 0;
	}
	.sec-qa dl dt::before,
	.sec-qa dl dd::before {
		position: absolute;
		top: 24px;
		left: 0;
		width: 47px;
		height: 47px;
		color: #fff;
		font-weight: bold;
		font-size: 20px;
		border-radius: 50%;
		text-align: center;
		display: flex;
		display: -webkit-flex;
		display: -ms-flexbox;
		-webkit-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}
	.sec-qa dl dt::before {
		content: "Q";
		background-color: var(--main);
	}
	.sec-qa dl dd::before {
		content: "A";
		background-color: #979797;
	}
	.sec-qa dl dt {
		font-weight: bold;
		font-size: 24px;
		border-bottom: 1px solid #ccc;
	}
	.sec-qa dl dd {
		font-size: 20px;
	}
	.sec-ship .address {
		padding: 15px 30px;
		box-sizing: border-box;
	}
	.sec-ship .address .box {
		font-size: 18px;
		vertical-align: text-top;
		margin-bottom: 15px;
	}
	.sec-ship .address .box .icon {
		margin-right: 8px;
	}
	.sec-ship .address .box img {
		vertical-align: baseline;
	}
	.add-btn {
		text-align: right;
	}
	.add-btn .btn {
		display: inline-block;
		margin: 15px auto;
		padding: 10px 20px;
		background: #0b7ec3;
		color: #fff;
		border-radius: 5px;
	}
}
